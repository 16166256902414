"use client";

import { api } from "@/api";
import { useMutation } from "@tanstack/react-query";

export function useSignOut() {
  return useMutation({
    mutationFn: async () => {
      await api.post<Response>("auth/signOut");
    },
  });
}
