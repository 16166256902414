import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/workspace/components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SideBarNavigation"] */ "/workspace/components/SideBarNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SignOutButton"] */ "/workspace/components/SignOutButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/Menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/CssBaseline/CssBaseline.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/Grid/Grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientContext","QueryClientProvider","useQueryClient"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/workspace/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/styles.css");
