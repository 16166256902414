"use client";

import { Stack, SvgIconTypeMap, Tooltip } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Fragment, ReactNode } from "react";

import { usePathname } from "next/navigation";
import Link from "next/link";
import MapIcon from "@mui/icons-material/Map";
import GroupsIcon from "@mui/icons-material/Groups";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { User } from "@/types";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DialpadIcon from "@mui/icons-material/Dialpad";
import ListAltIcon from "@mui/icons-material/ListAlt";

type Props = {
  menuIsOpen: boolean;
  self: User;
};

const linksMap: Record<
  string,
  [ReactNode, string | string[], OverridableComponent<SvgIconTypeMap>][]
> = {
  dispatcher: [
    ["Fire Notification", ["/", "/incidents"], DashboardIcon],
    ["Dispatch Connect", "/dispatch-connect", EmailIcon],
    ["My Account", "/my-account", SettingsIcon],
  ],

  superAdmin: [
    ["Fire Notification", ["/"], DashboardIcon],
    ["Users", "/users", GroupsIcon],
    ["Audit Log", "/audit", ListAltIcon],
    ["Companies", "/companies", BusinessIcon],
    ["Dispatcher", ["/dispatcher", "/incidents"], SupportAgentIcon],
    ["Dispatch Connect", "/dispatch-connect", EmailIcon],
    ["Call Types", "/call-types", DialpadIcon],
    ["Generate Report", "/generate-report", NoteAddIcon],
    ["Account Alerts", "/account-alerts", NotificationsIcon],
    ["My Account", "/my-account", SettingsIcon],
  ],

  hobbyist: [
    ["Fire Notification", ["/", "/incidents"], DashboardIcon],
    ["Map", "/map", MapIcon],
    // ["Account Alerts", "/account-alerts", NotificationsIcon],
    ["My Account", "/my-account", SettingsIcon],
  ],

  admin: [
    ["Fire Notification", ["/", "/incidents"], DashboardIcon],
    ["Map", "/map", MapIcon],
    ["Dispatch Connect", "/dispatch-connect", EmailIcon],
    ["Users", "/users", GroupsIcon],
    // ['Employees', '/employees', PersonIcon],
    ["Call Types", "/call-types", DialpadIcon],
    ["Generate Report", "/generate-report", NoteAddIcon],

    ["Account Alerts", "/account-alerts", NotificationsIcon],
    ["My Account", "/my-account", SettingsIcon],
  ],

  employee: [
    ["Fire Notification", ["/", "/incidents"], DashboardIcon],
    ["Map", "/map", MapIcon],
    ["Dispatch Connect", "/dispatch-connect", EmailIcon],
    ["Call Types", "/call-types", DialpadIcon],
    ["Generate Report", "/generate-report", NoteAddIcon],

    ["Account Alerts", "/account-alerts", NotificationsIcon],
    ["My Account", "/my-account", SettingsIcon],
  ],
};

export function SideBarNavigation({ menuIsOpen, self }: Props) {
  const pathName = usePathname();
  const links = linksMap[self?.Roles![0].id];

  return (
    <Stack
      component="nav"
      sx={{
        marginTop: "3rem",
        width: menuIsOpen ? undefined : "55px",
      }}
    >
      {links?.map(([label, hrefs, Icon], index) => {
        const href = typeof hrefs === "string" ? hrefs : hrefs[0];

        /**
         * This supports tabs that don't share a prefix
         */
        const isActive = (typeof hrefs === "string" ? [hrefs] : hrefs).some(
          (href) =>
            href === "/" ? pathName === "/" : pathName.startsWith(href),
        );

        const Wrapper = menuIsOpen ? Fragment : Tooltip;

        const wrapperProps = menuIsOpen
          ? {}
          : {
              arrow: true,
              title: label,
              placement: "right",
            };

        return (
          // @ts-ignore
          <Wrapper key={index} {...wrapperProps}>
            <Stack
              component={Link}
              href={href}
              sx={{
                height: "56px",
                color: "#263238",
                display: "flex",
                alignItems: "center",
                width: "100%",
                padding: "1rem",
                flexDirection: "row",
                whiteSpace: "nowrap",
                overflow: "hidden",

                ...(isActive
                  ? {
                      backgroundColor: "#4487EE",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }
                  : undefined),
              }}
            >
              <Icon
                sx={{
                  marginRight: "1rem",
                }}
              />

              {label}
            </Stack>
          </Wrapper>
        );
      })}
    </Stack>
  );
}
