"use client";

import { useSignOut } from "@/hooks/useSignOut";
import LogoutIcon from "@mui/icons-material/Logout";
import { CircularProgress, Stack, Tooltip } from "@mui/material";
import { useRouter } from "next/navigation";
import { useCallback } from "react";

type Props = {
  menuIsOpen: boolean;
};

export function SignOutButton({ menuIsOpen }: Props) {
  const signOut = useSignOut();
  const router = useRouter();

  const onClick = useCallback(() => {
    signOut.mutate(undefined, {
      onSuccess: () => {
        // router.refresh()
        router.push("/auth/sign-in");
      },
    });
  }, []);

  const disabled = signOut.isPending || signOut.isSuccess;

  return (
    <Tooltip title={menuIsOpen ? "" : "Sign Out"} placement="right">
      <Stack
        component="button"
        type="button"
        disabled={disabled}
        onClick={onClick}
        sx={{
          borderRadius: "8px",
          outline: "0.5px solid #CFD8DD",
          textAlign: "left",
          padding: "0.8rem 1rem",
          flexDirection: "row",
          gap: "0.5rem",
          whiteSpace: "nowrap",
          overflowX: "hidden",

          "&:disabled": {
            opacity: 0.5,
          },

          width: menuIsOpen ? "100%" : "55px",
        }}
      >
        {menuIsOpen || !disabled ? <LogoutIcon /> : null}

        {menuIsOpen ? "Sign Out" : null}

        {disabled ? (
          <CircularProgress
            size="1.5rem"
            sx={{
              margin: "auto",
              marginRight: "0",
            }}
          />
        ) : null}
      </Stack>
    </Tooltip>
  );
}
